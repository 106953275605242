export const columnOne = [
  {
    title: "What is the purpose of the Culture Equality Index (CEI)?",
    body: `In today’s ever-changing work environment, we need all employees to  create cultures that value difference and harness diversity to innovate, create and advance the organisation's aims. Organisations that are dedicated to creating this environment invest in enabling their employees to practise inclusion. The Culture of Equality Index (CEI) is a self-assessment diagnostic tool that measures peoples perceptions of inclusive behaviours that create a culture of equality in an organisation. It provides a deep dive assessment into the three key factors that make up cultures of equality, these include awareness, understanding and inclusive practices.`,
  },
  {
    title: "How will the Culture Equality Index (CEI) benefit me?",
    body: `The CEI will provide insight and understanding into how employees experience the culture within their organisation. `,
  },
  {
    title: "Who is the Culture Equality Index (CEI) assessment for?",
    body: "The CEI can be taken by any employee within the organisation.",
  },
  {
    title:
      "How can I best prepare for taking the Culture Equality Index (CEI)?",
    body: `The CEI is a self-assessment diagnostic tool, which measures shared perceptions employees have of the culture of equality in their organisation. It provides a deep dive assessment into the three key foundational elements of a culture of equality, these include:
      Awareness: Do employees understand how inequality works in organisations, and why it is a imperative to tackle this? 
      Understanding: Are employees aware of their values, and how these values create their teams’ culture? 
      Practice: Do leaders know how to practice inclusion at work? In doing so invite employees to do the same.`,
  },
  {
    title: "Why is this specific assessment being used?",
    body:
      "Currently most culture assessment tools, measure general aspects of organisational culture, like engagement and job satisfaction. These measures do not provide an assessment of the action’s employees are taking to build cultures of equality within their teams. The CEI is the go-to diagnostic tool to assess, evaluate and continuously monitor what employees are doing to build cultures of equality within their teams and organisations. The tool is based on over 10 years of academic research, and experience developing leaders and building cultures of equality in organisations. The assessment is a data driven, in-depth measurement tool with automated reporting, to provide all people managers with a clear roadmap of what they need to do to advance equality within their teams.",
  },
  {
    title: "What information should I receive beforehand from my company?",
    body: `The organisation requiring you to complete the CEI should have provided you with any available practice materials and all the practical information that it is possible to give. This includes the reasons why you are being asked to take the CEI, how it will be administered, the date, time and location of the CEI session, how long the CEI will last, and details of how to prepare for the CEI. This information might be provided by email beforehand. As the CEI is administered unsupervised and online, please check that you do have the capability to do it:
    Do you have access to a computer with an internet connection?
    Will you be able to work on it in a quiet setting, free from distractions for the necessary period of time?
    If you have any worries about completing the CEI over the internet, let the CEI provider know as soon as possible so that alternative arrangements can be made.`,
  },
];

export const columnTwo = [
  {
    title: "How are people with disabilities treated?",
    body:
      "If you or your dependant have a disability and feel that you may need special conditions, it is important that this is brought to the notice of the person responsible for the CEI as soon as the CEI session has been arranged. This will give maximum time for the assessor to check what special requirements can be provided and what arrangements can be made.",
  },
  {
    title: "When will the results of the CEI be given to me?",
    body:
      "The information about giving the CEI results should be given to you when you complete the assessment from your organisation. Your organisation will be provided with a report detailing the overall results, no individual responses will be provided and individuals cannot be identified in the findings. This is to ensure confidentiality. The use of this questionnaire is limited to those people who have received specialist training in its use and interpretation. The report herein is generated from the results of a questionnaire answered by the respondent(s) and substantially reflects the answers made by them. Due consideration must be given to the subjective nature of questionnaire-based ratings in the interpretation of this data. This report has been generated electronically – the user of the software can make amendments and additions to the text of the report.",
  },
  {
    title: "How will I understand what the results mean?",
    body:
      "You will be provided with a summary of the CEI findings by your organisation.",
  },
  {
    title: "How are my results scored?",
    body: `Scores (e.g. 8 out of 10) obtained on the CEI are typically converted into a ‘standard’ form to facilitate their interpretation. This may be carried out by using tables of ‘norms’ or by reference to criterion scores. Norms provide information about the distribution of scores in some population (for example, ‘Managers in the United States’) and scores can be converted into numbers that show how a person has performed relative to this population.
    Instead of saying the persons responses indicate a result of 8 out of 10, we might say they performed at a level equivalent to the top 30 percent of the Managers in the United States population. Norms are important because the latter type of statement is more meaningful and useful than the former. The CEI is intended to show up differences where these are real. What they should not do is discriminate unfairly. That is, show differences where none exist, or fail to show differences that do exist.`,
  },
  {
    title: "Who can I contact if I have questions?",
    body:
      "For any queries please contact info@cultureequalityindex or visit www.cultureequalityindex.com ",
  },
];
