import React, { useState, memo, useEffect } from "react";
import * as _ from "lodash";

import firebase from "../../firebase";
import { ExportToCsv } from "export-to-csv";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Link } from "react-router-dom";

import { Tooltip, Modal } from "@material-ui/core";
import { sortSection, subSectionCalculations } from "../../utils";
import {
  Results,
  ResultsHeader,
  PDFdocument,
  FooterV2,
} from "../../components";

import { KeyboardArrowLeft, GetApp, ChevronRight } from "@material-ui/icons";

export const ReportCompany = (props) => {
  const { history, match, admin } = props;
  const [activeSection, setActiveSection] = useState("summary");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersDetails, setUsersDetails] = useState([]);
  const [sectionsNormGroup, setSectionsNormGroup] = useState({});
  const [sortedByName, setSortedByName] = useState([]);
  const [show, hide] = useState(false);
  const [sectionsSummary, setSectionsSummary] = useState([]);
  const [subSectionsFeedback, setSubSectionsFeedback] = useState([]);
  const { projectName, companyId, timeStamp } = match.params;
  const [liveVersion, setLiveVersion] = useState(null);
  const [usersCompletedAssessment, setUsersCompletedAssessment] = useState(0);
  const [downloadModal, showDownloadModal] = useState(false);

  useEffect(() => {
    const db = firebase.firestore();
    const liveAssessmentVersion = db
      .collection(projectName)
      .doc("cms")
      .collection("published")
      .doc("live");

    const unsubscribe = liveAssessmentVersion.onSnapshot((doc) => {
      if (!_.isEqual(liveVersion, doc.data().version)) {
        setLiveVersion(doc.data().version);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const db = firebase.firestore();
    if (liveVersion) {
      const allAssessments = db
        .collection(projectName)
        .doc("assessments")
        .collection(liveVersion)
        .doc("all");

      const userData = db
        .collection(projectName)
        .doc("users")
        .collection("user")
        .doc("all");

      const unsubscribeAssessments = allAssessments.onSnapshot((doc) => {
        if (doc.exists) {
          const sectionNormGroup = doc.data().assessment;
          setSectionsNormGroup(sectionNormGroup);
          const resultsData = [];

          sectionNormGroup &&
            Object.keys(sectionNormGroup).length > 0 &&
            Object.keys(sectionNormGroup)
              .sort()
              .forEach((section) => {
                Object.keys(sectionNormGroup[section])
                  .sort()
                  .forEach((subSection) => {
                    const subsectionPercentileRank = subSectionCalculations(
                      sectionNormGroup,
                      section,
                      subSection,
                      selectedUsers
                    );

                    const emails = usersDetails.map(({ email }) => email);
                    const names = usersDetails.map(
                      ({ displayName }) => displayName
                    );
                    const companies = usersDetails.map(
                      ({ company }) => company
                    );

                    const normGroupSubSection =
                      sectionNormGroup &&
                      sectionNormGroup[section] &&
                      sectionNormGroup[section][subSection];
                    const selectedUsersScores = [];

                    selectedUsers.forEach((selectedUserId) => {
                      return normGroupSubSection &&
                        normGroupSubSection[selectedUserId]
                        ? selectedUsersScores.push(
                            normGroupSubSection[selectedUserId]
                          )
                        : true;
                    });

                    setUsersCompletedAssessment(selectedUsersScores.length);

                    resultsData.push({
                      Company: companies.sort().toString(),
                      Names: names.sort().toString(),
                      Emails: emails.sort().toString(),
                      Section: section,
                      Subsection: subSection,
                      "Percentile rank": subsectionPercentileRank,
                    });
                  });
              });

          const sortedByNames = resultsData.sort((a, b) => {
            if (a["Names"].toLowerCase() < b["Names"].toLowerCase())
              //sort string ascending
              return -1;
            if (a["Names"].toLowerCase() > b["Names"].toLowerCase()) return 1;
            return 0; //default return value (no sorting)
          });

          setSortedByName(sortedByNames);
        } else {
          setSectionsNormGroup({});
        }
      });

      const unsubscribeUsersData = userData.onSnapshot((doc) => {
        const filteredByCompany = Object.keys(doc.data())
          .sort()
          .filter((userId) => {
            return (
              doc.data()[userId].company &&
              doc
                .data()
                [userId].company.toLowerCase()
                .includes(decodeURI(companyId).toLowerCase())
            );
          });

        //  if we add any future norm group specifics - they'll need to be added HERE!
        setSelectedUsers(filteredByCompany);
        setUsersDetails(filteredByCompany.map((uid) => doc.data()[uid]));
      });

      hide(true);

      return () => {
        // Unmouting
        unsubscribeAssessments();
        unsubscribeUsersData();
      };
    }
  }, [match.params, liveVersion]);

  useEffect(() => {
    sectionsNormGroup &&
      Object.keys(sectionsNormGroup).length > 0 &&
      Object.keys(sectionsNormGroup)
        .sort()
        .forEach((section) => {
          const selectedUsersScores = [];

          Object.keys(sectionsNormGroup[section])
            .sort()
            .forEach((subSection) => {
              const normGroupSubSection =
                sectionsNormGroup &&
                sectionsNormGroup[section] &&
                sectionsNormGroup[section][subSection];

              selectedUsers.forEach((selectedUserId) => {
                if (
                  !selectedUsersScores.includes(selectedUserId) &&
                  normGroupSubSection &&
                  normGroupSubSection[selectedUserId]
                ) {
                  selectedUsersScores.push(selectedUserId);
                }
              });
            });

          setUsersCompletedAssessment(
            Object.values(selectedUsersScores).length
          );
        });
  }, [sectionsNormGroup, selectedUsers]);

  useEffect(() => {
    const { projectName } = match.params;
    if (liveVersion) {
      const db = firebase.firestore();
      const currentAssesmentVersion = db
        .collection(projectName)
        .doc("cms")
        .collection("version")
        .doc(liveVersion)
        .collection("assessment")
        .doc("sections")
        .get();

      currentAssesmentVersion.then((doc) => {
        const sectionKeys = Object.keys(doc.data()).sort();

        const sectionsFeedback = sectionKeys.map((sectionKey) => {
          return {
            body: doc.data()[sectionKey].body,
            section: sectionKey,
          };
        });

        const subsectionsFeedback = sectionKeys.map((sectionKey) => {
          return Object.keys(doc.data()[sectionKey].subsections)
            .sort()
            .map((subsectionKey) => {
              return {
                feedback: doc.data()[sectionKey].subsections[subsectionKey]
                  .feedback,
                subSection: subsectionKey,
              };
            });
        });

        if (projectName.includes("cultureEqualityIndex")) {
          const awarenessSummary = sectionsFeedback.find(({ section }) =>
            section.includes("Awareness")
          );
          const practiceSummary = sectionsFeedback.find(({ section }) =>
            section.includes("Practice")
          );
          const understandingSummary = sectionsFeedback.find(({ section }) =>
            section.includes("Understanding")
          );
          setSectionsSummary([
            awarenessSummary,
            understandingSummary,
            practiceSummary,
          ]);
        } else {
          setSectionsSummary(sortSection(sectionsFeedback));
        }

        setSubSectionsFeedback(subsectionsFeedback.flat());
      });
    }
  }, [match.params, liveVersion]);

  const selectSection = (event, section) => {
    if (section !== null) {
      setActiveSection(section);
    }
  };

  const resultsAvailable =
    activeSection &&
    selectedUsers &&
    typeof sectionsNormGroup === "object" &&
    usersCompletedAssessment > 0;

  let normGroupData = sectionsNormGroup;

  if (
    sectionsNormGroup &&
    sectionsNormGroup.Awareness &&
    sectionsNormGroup.Understanding &&
    sectionsNormGroup.Practice
  ) {
    normGroupData = {
      Awareness: sectionsNormGroup.Awareness,
      Understanding: sectionsNormGroup.Understanding,
      Practice: sectionsNormGroup.Practice,
    };
  }
  return (
    <>
      <div className="page">
        <div className="toolbar">
          <div className="toolbar-items">
            {admin && (
              <button
                className="secondary back"
                onClick={() => history.push(`/${projectName}/users`)}
                disabled={false}
              >
                <KeyboardArrowLeft />
                Dashboard
              </button>
            )}
          </div>
          <div className="toolbar-items">
            <h1 className="project-title">Culture Equality Index</h1>
          </div>

          <div className="toolbar-items download-csv-container"></div>
        </div>

        <div className="report-company-container">
          <div className="about-report-header">
            <p>
              <strong>Company report</strong>
            </p>

            {resultsAvailable && (
              <button
                className="download-button"
                onClick={() => showDownloadModal(true)}
              >
                Download
                <GetApp />
              </button>
            )}
          </div>
          <div className="details-container">
            <h1>{decodeURI(match.params.companyId)}</h1>
            <div className="assessment-stats"></div>

            {usersCompletedAssessment > 0 && (
              <ResultsHeader
                activeSection={activeSection}
                selectSection={selectSection}
                sectionsSummary={sectionsSummary}
              />
            )}
          </div>

          {resultsAvailable ? (
            <Results
              activeSection={activeSection}
              sectionsNormGroup={normGroupData}
              subSectionsFeedback={subSectionsFeedback}
              selectedUsers={selectedUsers}
              match={match}
            />
          ) : (
            <p style={{ paddingBottom: "1.5rem" }}>No results available...</p>
          )}
        </div>

        <Modal
          open={downloadModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="modal-container">
            <div className="modal-content">
              <h2>Which download format do you require?</h2>

              {show &&
                sortedByName &&
                sortedByName.length > 0 &&
                subSectionsFeedback.length > 0 &&
                usersCompletedAssessment > 0 &&
                sectionsSummary.length > 0 && (
                  <>
                    {/*<label>Download</label> */}

                    <div>
                      <Tooltip
                        title={`Download companies percentile rank data`}
                        aria-label={`Download companies percentile rank data`}
                      >
                        <button
                          className="tertiary action-button"
                          onClick={() => {
                            const options = {
                              fieldSeparator: ",",
                              quoteStrings: '"',
                              decimalSeparator: ".",
                              showLabels: true,
                              showTitle: true,
                              title:
                                "User's last completed assessment percentile rank",
                              useTextFile: false,
                              useBom: true,
                              useKeysAsHeaders: true,
                            };

                            const csvExporter = new ExportToCsv(options);

                            csvExporter.generateCsv(sortedByName);
                          }}
                          disabled={false}
                        >
                          <strong>.csv</strong>
                        </button>
                      </Tooltip>

                      <PDFDownloadLink
                        document={
                          <PDFdocument
                            data={sortedByName}
                            feedback={subSectionsFeedback}
                            sectionsSummary={sectionsSummary}
                          />
                        }
                        fileName="results.pdf"
                        style={{
                          textDecoration: "none",
                          color: "#4d71f7",
                          background: "white",
                          margin: "0.5rem 0.5rem 0.5rem 0",
                          width: "3rem",
                          fontSize: "16px",
                          fontFamily: "HK Grotesk",
                          fontWeight: "bold",
                          lineHeight: "140%",
                          height: "3rem",
                          position: "relative",
                          borderRadius: "4px",
                          display: "inline-flex",
                          border: "1px solid #c9c9c9",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {({ loading }) => (loading ? ".pdf" : ".pdf")}
                      </PDFDownloadLink>
                    </div>
                  </>
                )}

              <div className="cta-container">
                <button
                  className="primary action-button"
                  style={{ color: "white" }}
                  onClick={() => {
                    showDownloadModal(false);
                  }}
                  disabled={false}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <FooterV2 projectName={projectName} />
    </>
  );
};

export default memo(ReportCompany);
