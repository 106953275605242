export const pdfText = [
  {
    title: "The Report",
    body: [
      "The Culture of Equality Index is a diagnostic tool to assess, evaluate and continuously monitor the lived experience of the organisation’s culture.  The responses have been aggregated and compared with a large group of people who have answered in the same questionnaire to measure employees’ experiences of inequality regarding policies, processes, practices, and personal beliefs. It provides a baseline of your inclusive workplace culture, which helps establish a business case for why cultural change is needed. When considering this report’s description of your workplace culture, it is important to recognise that it is based on the answers given, representing how employees view behaviours and norms in relation to equality at work.",
      "The report enables organisations to leverage the data-informed insights to develop a targeted approach to building an inclusive culture. The single most important thing companies can do to survive in the future world of work is build a workplace that works for everyone. For employees to thrive in the future, they need to know that their unique talents, capabilities, skill sets, identities, and perspectives will be valued—this requires a culture of equality. Creating cultures of equality requires inclusive leadership, which improves productivity, profitability, innovation, creativity, and performance. This report details the collective strengths and development opportunities for making the workplace culture more inclusive and equitable. The results are summarised under the three domains of awareness, understanding, and practice."
    ],
  },
  {
    title: "Confidentiality",
    body: [
      "Equality Forward is committed to the protection of any personal data we obtain from you through our website or through any other means. Our service involves the production of an automated report based on your assessment submissions. We use all information we collect so that we can provide our online assessment tool, provide our employer clients with automated reports, provide information about our service and answer enquiries about our business. For more information see our Privacy Policy.",
    ],
  },
];
