import React, { memo, useEffect, useCallback } from "react";
import question from "../../assets/icons/question.svg";
import alert from "../../assets/icons/alert.svg";
import time from "../../assets/icons/time.svg";
import mindset from "../../assets/icons/brain.svg";
// import mindset from "../../assets/icons/smiley.svg";
import mute from "../../assets/icons/mute.svg";
import list from "../../assets/icons/list.svg";
import firebase from "../../firebase";

import { FooterV2 } from "../../components";

const AssessmentIntro = ({
  sendValuesToParent,
  history,
  setAssessmentTimestampStart,
  match,
}) => {
  const { projectName } = match.params;

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            /**
             * CHECK CURRENT USER IS ADMIN
             */
            const { claims } = idTokenResult;

            if (claims.isAdmin) {
              history.push(`/${projectName}/users`);
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });
  }, [history]);

  const onExit = useCallback((event) => {
    event.preventDefault();

    return (event.returnValue = "Are you sure you want to close?");
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", onExit);

    return () => {
      window.removeEventListener("beforeunload", (ev) => onExit);
    };
  }, []);

  return (
    <>
      <div className="assessmentIntro-page">
        <div className="assessment-intro">
          <h1>All set!</h1>
          <h2>Ready to take the Culture Equality Index assessment?</h2>
          <div className="body">
            <div className="section">
              <div className="panel">
                <div className="icon-container">
                  <img src={question} alt="question" />
                </div>
                <div className="panel-text">
                  <p>
                    <strong>What is it?</strong>
                  </p>
                  <p>
                    A multiple choice questionnaire exploring your understanding
                    of equality in the workplace.
                  </p>
                </div>
              </div>
              <div className="panel">
                <div className="icon-container">
                  <img src={alert} alt="alert" />
                </div>
                <div className="panel-text">
                  <p>
                    <strong>One sitting</strong>
                  </p>
                  <p>
                    You need to complete the assessment in one sitting. If you
                    leave you will need to start again from the beginning.
                  </p>
                </div>
              </div>
            </div>
            <div className="section">
              <div className="panel">
                <div className="icon-container">
                  <img src={time} alt="time" />
                </div>
                <div className="panel-text">
                  <p>
                    <strong>Duration</strong>
                  </p>
                  <p>
                    The assessment should take around 15 minutes to complete.{" "}
                  </p>
                </div>
              </div>
              <div className="panel">
                <div className="icon-container">
                  <img src={mindset} alt="mindset" style={{ width: "24px" }} />
                </div>
                <div className="panel-text">
                  <p>
                    <strong>Mindset</strong>
                  </p>
                  <p>
                    There are no right or wrong answers. Our objective is to
                    open discussion.
                  </p>
                </div>
              </div>
            </div>
            <div className="section">
              <div className="panel">
                <div className="icon-container">
                  <img src={mute} alt="mute" />
                </div>
                <div className="panel-text">
                  <p>
                    <strong>Find a comfortable location</strong>
                  </p>
                  <p>
                    We recommend that you find a quiet, comfortable location to
                    complete the assessment.
                  </p>
                </div>
              </div>
              <div className="panel">
                <div className="icon-container">
                  <img src={list} alt="list" />
                </div>
                <div className="panel-text">
                  <p>
                    <strong>Outcome</strong>
                  </p>
                  <p>
                    Your organisation will be provided with a report detailing
                    the collective strengths and development opportunities when
                    it comes to creating cultures of equality. No individual
                    responses will be provided and individuals cannot be
                    identified in the findings.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="assessment-buttons">
            <button
              className="primary"
              onClick={() => {
                localStorage.setItem(
                  "assessmentTimestampStart",
                  JSON.stringify(Date.now())
                );
                history.push(`/${projectName}/assessment`);
              }}
              disabled={false}
            >
              I am ready to take the assessment now
            </button>
          </div>
        </div>
        <div className="faq-container">
          {/* <img src={person5} alt="" /> */}
          <div className="body">
            <h2>Have you got any questions?</h2>
            <p className="subtitle">
              Visit our FAQ page - here you will find guidance and information
              to your questions.
            </p>

            <button
              className="secondary"
              onClick={() => history.push(`/${projectName}/faq`)}
              disabled={false}
            >
              FAQ
            </button>
          </div>
        </div>
        <FooterV2 projectName={projectName} />
      </div>
    </>
  );
};

export default memo(AssessmentIntro);
