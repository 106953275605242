import "./App.scss";
import withFirebaseAuth from "react-with-firebase-auth";

import React, { useState, useEffect } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import ReactGA from "react-ga";

import { Layout, PrivateRoute } from "./components";
import firebase from "./firebase";

import {
  Assessment,
  Onboarding,
  CookiePolicy,
  PrivacyPolicy,
  PrivacyPolicyLong,
  AssessmentIntro,
  Login,
  Landing,
  Users,
  User,
  AddUser,
  ReportCompany,
  ReportUser,
  CMS,
  CMSAssessmentVersion,
  CMSAssessmentQuestions,
  CMSAssessmentSections,
  CMSAssessmentSubsections,
  CMSOnboarding,
  FAQ,
  Error,
  Forbidden,
  ReportAbout,
} from "./pages";
import history from "./history";

export const App = (props) => {
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = props;

  useEffect(() => {
    ReactGA.initialize("UA-184176645-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    const unlisten = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            /**
             * CHECK CURRENT USER IS ADMIN
             */
            const { claims } = idTokenResult;

            if (claims.isAdmin) {
              setIsAdmin(claims.isAdmin ? claims.isAdmin : false);
              setIsUser(false);
            } else if (claims.isUser) {
              setIsUser(claims.isUser ? claims.isUser : false);
              setIsAdmin(false);
            } else {
              setIsUser(false);
              setIsAdmin(false);
            }
            setIsLoading(false);
          })
          .catch((e) => {
            setIsLoading(false);
            console.error(e);
          });
      } else {
        setIsLoading(false);
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <CircularProgress
          style={{
            width: "40px",
            height: "40px",
            position: "fixed",
            top: "50%",
            bottom: "0",
            right: "0",
            left: "0",
            margin: "0 auto",
          }}
        />
      ) : (
        <Router history={history}>
          <Layout
            user={user}
            showHeader={user && user.uid && (isAdmin || isUser)}
            history={history}
            admin={isAdmin}
            setIsUser={setIsUser}
            setIsAdmin={setIsAdmin}
            path={window.location.pathname}
          >
            <Switch>
              <PrivateRoute
                user={user}
                exact
                path="/:projectName/faq"
                component={(routeProps) => {
                  return <FAQ {...routeProps} />;
                }}
              />

              <PrivateRoute
                user={user}
                exact
                path="/:projectName/onboarding"
                component={(routeProps) => {
                  return <Onboarding user={user} {...routeProps} />;
                }}
              />

              <PrivateRoute
                user={user}
                exact
                path="/:projectName/users"
                component={(routeProps) => {
                  return <Users {...routeProps} admin={isAdmin} />;
                }}
              />

              <PrivateRoute
                user={user}
                exact
                path="/:projectName/user/:userId"
                component={(routeProps) => {
                  return <User {...routeProps} admin={isAdmin} />;
                }}
              />

              <PrivateRoute
                user={user}
                exact
                path="/:projectName/add-user"
                component={(routeProps) => {
                  return (
                    <AddUser {...routeProps} currentUserEmail={user.email} />
                  );
                }}
              />

              <PrivateRoute
                user={user}
                exact
                path="/:projectName/cms"
                component={(routeProps) => {
                  return <CMS {...routeProps} />;
                }}
              />

              <Route
                user={user}
                exact
                path="/:projectName/cookie-policy"
                component={(routeProps) => {
                  return <CookiePolicy {...routeProps} />;
                }}
              />

              <Route
                user={user}
                exact
                path="/:projectName/privacy-policy"
                component={(routeProps) => {
                  return (
                    <PrivacyPolicy
                      {...routeProps}
                      isAdmin={isAdmin}
                      isUser={isUser}
                      user={user}
                    />
                  );
                }}
              />

              <Route
                user={user}
                exact
                path="/:projectName/privacy-policy-long"
                component={(routeProps) => {
                  return (
                    <PrivacyPolicyLong
                      {...routeProps}
                      isAdmin={isAdmin}
                      isUser={isUser}
                      user={user}
                    />
                  );
                }}
              />

              <Route
                user={user}
                exact
                path="/:projectName/login"
                component={(routeProps) => {
                  return (
                    <Login
                      {...routeProps}
                      isAdmin={isAdmin}
                      setIsAdmin={setIsAdmin}
                      setIsUser={setIsUser}
                      history={history}
                      setIsLoading={setIsLoading}
                    />
                  );
                }}
              />

              <Route
                user={user}
                exact
                path="/:projectName/landing"
                render={(routeProps) => {
                  return (
                    <Landing
                      {...routeProps}
                      isAdmin={isAdmin}
                      setIsAdmin={setIsAdmin}
                      isUser={isUser}
                      setIsUser={setIsUser}
                      setIsLoading={setIsLoading}
                      setEmail={setEmail}
                      email={email}
                    />
                  );
                }}
              />

              <Route
                user={user}
                exact
                path="/:projectName/report-about"
                render={(routeProps) => {
                  return (
                    <ReportAbout
                      {...routeProps}
                      isAdmin={isAdmin}
                      setIsAdmin={setIsAdmin}
                      isUser={isUser}
                      setIsUser={setIsUser}
                      setIsLoading={setIsLoading}
                      setEmail={setEmail}
                      email={email}
                    />
                  );
                }}
              />

              <PrivateRoute
                user={user}
                exact
                path="/:projectName/assessment-intro"
                component={(routeProps) => {
                  return <AssessmentIntro {...routeProps} />;
                }}
              />

              <PrivateRoute
                user={user}
                exact
                path="/:projectName/assessment"
                component={(routeProps) => {
                  return <Assessment {...routeProps} user={user} />;
                }}
              />

              <PrivateRoute
                user={user}
                exact
                path="/user/:userId/:projectName/:timeStamp"
                component={(routeProps) => {
                  return (
                    <ReportUser {...routeProps} user={user} admin={isAdmin} />
                  );
                }}
              />

              <PrivateRoute
                user={user}
                exact
                path="/:projectName/company/:companyId"
                component={(routeProps) => {
                  return (
                    <ReportCompany
                      {...routeProps}
                      user={isUser}
                      admin={isAdmin}
                    />
                  );
                }}
              />

              <PrivateRoute
                user={user}
                exact
                path="/:projectName/cms/:assessmentVersion"
                component={(routeProps) => {
                  return <CMSAssessmentVersion {...routeProps} />;
                }}
              />

              <PrivateRoute
                user={user}
                exact
                path="/:projectName/cms/:assessmentVersion/assessment/sections"
                component={(routeProps) => {
                  return <CMSAssessmentSections {...routeProps} />;
                }}
              />

              <PrivateRoute
                user={user}
                exact
                path="/:projectName/cms/:assessmentVersion/assessment/sections/:sectionId/subSections"
                component={(routeProps) => {
                  return <CMSAssessmentSubsections {...routeProps} />;
                }}
              />

              <PrivateRoute
                user={user}
                exact
                path="/:projectName/cms/:assessmentVersion/onboarding"
                component={(routeProps) => {
                  return <CMSOnboarding {...routeProps} />;
                }}
              />

              <PrivateRoute
                user={user}
                exact
                path="/:projectName/cms/:assessmentVersion/assessment/sections/:sectionId/subSections/:subSectionId"
                component={(routeProps) => {
                  return <CMSAssessmentQuestions {...routeProps} />;
                }}
              />

              <Route
                user={user}
                exact
                path="/:projectName/error"
                component={(routeProps) => {
                  return <Error {...routeProps} />;
                }}
              />

              <Route
                user={user}
                exact
                path="/:projectName/forbidden"
                component={(routeProps) => {
                  return <Forbidden {...routeProps} user={user} />;
                }}
              />

              <Redirect to={"/cultureEqualityIndex/error"} />
            </Switch>
          </Layout>
        </Router>
      )}
    </>
  );
};

const firebaseAppAuth = firebase.auth();

export default withFirebaseAuth({
  firebaseAppAuth,
})(App);
