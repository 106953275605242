import React, { useState, useEffect } from "react";
import firebase from "../../firebase";
import logo from "../../assets/img/logo-colour.svg";
import magicLink from "../../assets/icons/magic-link.svg";
import { Link } from "react-router-dom";

const Landing = ({
  history,
  setEmail,
  email,
  isUser,
  setIsUser,
  setIsAdmin,
  setIsLoading,
  match,
}) => {
  const [error, setError] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [redirect, showRedirect] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { projectName } = match.params;

  useEffect(() => {
    setError("");
    showRedirect(false);
  }, [window.location.href]);

  useEffect(() => {
    if (!buttonText) {
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        setButtonText("Log in");
      } else {
        setButtonText("Send magic link!");
      }
    }
  }, [buttonText]);

  const confirmMagicLink = (projectName) => {
    if (email) {
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then((user) => {
            firebase
              .auth()
              .currentUser.getIdTokenResult()
              .then((idTokenResult) => {
                /**
                 * CHECK CURRENT USER IS ADMIN
                 */
                const { claims } = idTokenResult;

                if (claims.isAdmin) {
                  setIsAdmin(claims.isAdmin ? claims.isAdmin : false);
                } else if (claims.isUser) {
                  setIsUser(claims.isUser ? claims.isUser : false);
                }
              })
              .catch((e) => {
                setError(e.message);
                console.error(e);
              });
          })
          .catch((error) => {
            showRedirect(true);
            console.error(error);
            setError(error.message);
          });
      } else {
        const sendEmailLinkInvitation = firebase
          .functions()
          .httpsCallable("sendEmailLink");

        sendEmailLinkInvitation({ email, projectName })
          .then(() => {
            setButtonText("Magic link has been sent!");
            setButtonDisabled(true);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else {
      setError("Please enter your email address");
    }
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            /**
             * CHECK CURRENT USER IS ADMIN
             */
            const { claims } = idTokenResult;

            if (claims.isAdmin) {
              history.push(`/${projectName}/users`);
            } else if (claims.isUser) {
              if (claims.acceptedPrivacyPolicy) {
                history.push(`/${projectName}/user/${user.uid}`);
              } else {
                history.push(`/${projectName}/privacy-policy`);
              }
            } else {
              history.push(`/${projectName}/forbidden`);
            }

            setIsLoading(false);
          })
          .catch((e) => {
            setIsLoading(false);
            console.error(e);
          });
      } else {
        setIsLoading(false);
      }
    });
  }, [history, setIsLoading]);

  const validate = (value, name) => {
    if (!value) {
      setError(`Please input ${name}`, name);
      return false;
    } else {
      let validationRegex;
      let error = "";

      switch (name) {
        case "email":
          validationRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          error = "Please check email address is formatted correctly";
          break;

        case "company":
          validationRegex = /^[A-Za-z\s]+$/;
          error = "Please only use alphabetic characters";
          break;

        case "name":
          validationRegex = /^[A-Za-z-]*$/;
          error = "Please remove any non alphabetic characters";
          break;

        default:
          break;
      }

      const passingValidation = validationRegex.test(
        String(value).toLowerCase()
      );

      setError(passingValidation ? "" : error);
      return passingValidation;
    }
  };

  return (
    <>
      <div className="landing">
        <div className="container">
          <div>
            <img
              src={logo}
              alt="Culture Equality Index"
              className="project-logo"
            />
            {buttonDisabled ? (
              <div className="content">
                <h1>Magic link is on it's way</h1>
                <p>
                  We sent the email to <strong>{email}</strong>
                  <br />
                  The link expires in 24 hours so be sure to use it soon.
                </p>

                <p className="selector">Go check your email</p>
              </div>
            ) : (
              <div className="content">
                <h1>Welcome to the Culture Equality Assessment</h1>
                <p className="selector">
                  Enabling employees to put equality into practice.
                </p>

                <p>Please enter your email to log-in or set-up your account.</p>

                <label className={`${error ? "error" : ""}`}>
                  Enter your email address
                </label>

                <input
                  value={email}
                  label="Email address"
                  maxLength="45"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onBlur={(e) => validate(e.target.value, "email")}
                  style={{
                    border: error ? "red solid 1px" : "",
                  }}
                />
                <p className="error">{error}</p>

                {!firebase
                  .auth()
                  .isSignInWithEmailLink(window.location.href) && (
                  <div className="magic-link-text">
                    <img src={magicLink} alt="magic link icon" />
                    <p style={{ paddingLeft: "1rem" }}>
                      To save you the hassle of passwords we will send you a
                      magic link that lets you sign-in automatically
                    </p>
                  </div>
                )}

                {redirect ? (
                  <div className="magic-link-text">
                    <img src={magicLink} alt="magic link icon" />
                    <p style={{ paddingLeft: "1rem", fontWeight: "bold" }}>
                      Looks like you had an issue with your magic link...
                      <Link to={`/${projectName}/landing`}>
                        Send yourself a new one!
                      </Link>
                    </p>
                  </div>
                ) : (
                  <button
                    className="primary"
                    onClick={() => confirmMagicLink(projectName)}
                    disabled={buttonDisabled}
                  >
                    {buttonText}
                  </button>
                )}
              </div>
            )}
          </div>
          <div>
            <p>
              Find out more about this service at our website{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.equalityforward.com"
              >
                https://www.equalityforward.com
              </a>
            </p>

            <p>
              Terms of service:{" "}
              <a href={`/${projectName}/privacy-policy-long`}>Privacy Policy</a>{" "}
              | <Link to="cookie-policy">Cookie Policy</Link>
            </p>
          </div>
        </div>
        <div className="container"></div>
      </div>
    </>
  );
};

export default Landing;
