import React, { useState } from "react";
import firebase from "../../firebase";
import Cookies from "universal-cookie";

import { CookieBanner } from "../../components";

import { Header } from "../";

import { Modal } from "@material-ui/core";
const cookies = new Cookies();

const Layout = (props) => {
  const {
    children,
    showHeader,
    user,
    history,
    admin,
    setIsAdmin,
    setIsUser,
    path,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(
    !cookies.get("acceptCookies")
  );

  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setIsAdmin(false);
        setIsUser(false);
        history.push(
          admin
            ? "/cultureEqualityIndex/login"
            : "/cultureEqualityIndex/landing"
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const isAsssessmentFlow =
    path.includes("assessment") || path.includes("onboarding");

  return (
    <div className="layout">
      {showHeader && (
        <Header
          email={user.email}
          logout={() => setModalOpen(true)}
          user={user}
        />
      )}
      {showCookieBanner && (
        <CookieBanner
          cookies={cookies}
          setShowCookieBanner={setShowCookieBanner}
        />
      )}
      {children}
      <Modal
        open={modalOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal-container sign-out">
          <div className="modal-content">
            {isAsssessmentFlow ? (
              <>
                <h2>Are you sure you want to leave?</h2>
                <p>
                  If you decide to leave now this assessment won’t be saved and
                  it will have to be retaken again.
                </p>
              </>
            ) : (
              <>
                <h2>Are you sure you want to leave?</h2>
              </>
            )}
            <div className="cta-container">
              <button
                className="tertiary action-button"
                onClick={() => {
                  setModalOpen(false);
                  signOut();
                }}
                disabled={false}
              >
                Sign out
              </button>
              <button
                className="primary action-button"
                onClick={() => {
                  setModalOpen(false);
                }}
                disabled={false}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Layout;
