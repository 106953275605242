import firebase from "firebase/app";
// import "firebase/database"; // If using Firebase database
// import "firebase/storage"; // If using Firebase storage
import "firebase/auth"; // If using auth
import "firebase/functions"; // If using functions
import "firebase/firestore"; // If using firestore

// PROD CONFIG
// const firebaseConfig = {
//   apiKey: "AIzaSyBrE67p5tXigf7HdGoOnpB78RLQJLER5G4",
//   authDomain: "equality-forward-cf035.firebaseapp.com",
//   databaseURL: "https://equality-forward-cf035.firebaseio.com",
//   projectId: "equality-forward-cf035",
//   storageBucket: "equality-forward-cf035.appspot.com",
//   messagingSenderId: "356399913763",
//   appId: "1:356399913763:web:cee8f5e4d5b523a55b0a96",
//   measurementId: "G-FFGBJRZ7XB",
// };

// TEST CONFIG
const firebaseConfig = {
  apiKey: "AIzaSyAWd6Rsa-EMsIf1fgD_Cza40bJ95kqKMZY",
  authDomain: "culture-equality-index-test.firebaseapp.com",
  projectId: "culture-equality-index-test",
  storageBucket: "culture-equality-index-test.appspot.com",
  messagingSenderId: "800712646689",
  appId: "1:800712646689:web:a2f95157d4413a166a0360",
};

firebase.initializeApp(firebaseConfig).auth();

export default firebase;
