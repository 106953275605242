import React, { memo } from "react";
import PropTypes from "prop-types";

const OpenQuestion = (props) => {
  const {
    question,
    maxLength,
    label,
    onBlurAction,
    onChangeAction,
    errorMessage,
  } = props;

  return (
    <div className="question">
      <h2 className="question-label">{question}</h2>
      <input
        maxLength={maxLength}
        label={label}
        className={`${
          errorMessage.length > 0 ? "error question-input" : "question-input"
        }`}
        type="text"
        onChange={(e) => {
          onChangeAction(e);
        }}
        onBlur={(e) => onChangeAction(e)}
      />
      {errorMessage.length > 0 && (
        <p className="error-message">{errorMessage}</p>
      )}
    </div>
  );
};

OpenQuestion.propTypes = {
  question: PropTypes.string,
};

OpenQuestion.defaultProps = {
  label: "",
  action: () => {},
};

export default memo(OpenQuestion);
