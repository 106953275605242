/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, memo, useEffect } from "react";

import firebase from "../../firebase";
import * as moment from "moment";
import _ from "lodash";

import { msToTime } from "../../utils";

import { FooterV2 } from "../../components";

import {
  Edit,
  KeyboardArrowLeft,
  Done,
  Send,
  GetApp,
  Assessment,
} from "@material-ui/icons";

import { Snackbar, SnackbarContent } from "@material-ui/core";

export const User = (props) => {
  const { history, match, admin } = props;
  const [assessments, setAssessments] = useState([]);
  const [liveVersion, setLiveVersion] = useState(null);
  const [pendingResults, setPendingResults] = useState(false);
  const [alerts, setAlerts] = useState("");

  const [error, setError] = useState({
    company: "",
    email: "",
    displayName: "",
  });

  // ADD alert to page
  const [editUser, setEditUser] = useState({
    company: false,
    email: false,
    displayName: false,
  });

  const [user, setUser] = useState({
    company: "",
    email: "",
    displayName: "",
  });
  const { projectName, userId } = match.params;
  const db = firebase.firestore();

  useEffect(() => {
    if (!admin) {
      const users = db
        .collection(projectName)
        .doc("users")
        .collection("user")
        .doc(String(userId));

      const unsubscribe = users.onSnapshot((doc) => {
        if (doc.data()) {
          if (!_.isEqual(pendingResults, doc.data().pendingResults)) {
            setPendingResults(doc.data().pendingResults);
          }
        }
      });

      return () => unsubscribe();
    }
  }, [admin]);

  useEffect(() => {
    const liveAssessmentVersion = db
      .collection(projectName)
      .doc("cms")
      .collection("published")
      .doc("live");

    const unsubscribe = liveAssessmentVersion.onSnapshot((doc) => {
      if (!_.isEqual(liveVersion, doc.data().version)) {
        setLiveVersion(doc.data().version);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (liveVersion) {
      const assessments = db
        .collection(projectName)
        .doc("assessments")
        .collection(liveVersion)
        .doc(userId);

      const unsubscribeAssessments = assessments.onSnapshot((doc) => {
        if (doc.exists) {
          setAssessments(doc.data());
        } else {
        }
      });

      return () => {
        // Unmouting
        unsubscribeAssessments();
      };
    }
  }, [liveVersion]);

  useEffect(() => {
    const userData = firebase
      .firestore()
      .collection(projectName)
      .doc("users")
      .collection("user")
      .doc(match.params.userId)
      .get();

    userData.then((doc) => {
      if (doc.data()) {
        const { company, email, displayName } = doc.data();
        const userData = {
          company,
          email,
          displayName,
        };

        const noMatch = !_.isEqual(userData, user);

        if (noMatch) {
          setUser(userData);
        }
      }
    });
  }, []);

  const validate = (value, name) => {
    setEditUser({
      ...editUser,
      [name]: !editUser[name],
    });

    if (!value) {
      setError({
        ...error,
        [name]: `Please input ${name === "displayName" ? "name" : name}`,
      });

      return false;
    } else {
      let validationRegex;
      let error = "";

      switch (name) {
        case "email":
          validationRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
          error = "Please check email address is formatted correctly";
          break;

        case "company":
          validationRegex = /^[A-Za-z\s0-9]+$/gi;
          error = "Please only use alphanumeric characters";
          break;

        case "displayName":
          validationRegex = /^[A-Za-z-\s]*$/gi;
          error = "Please remove any non alphabetic characters";
          break;

        default:
          break;
      }

      const passingValidation = validationRegex.test(
        String(value).toLowerCase()
      );

      if (passingValidation) {
        setError({
          company: "",
          email: "",
          displayName: "",
        });

        updateUser(value, name);
      }

      return passingValidation;
    }
  };

  const updateUser = (value, name) => {
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        /**
         * CHECK CURRENT USER IS ADMIN
         */
        const {
          claims: { isAdmin },
        } = idTokenResult;

        if (isAdmin) {
          const { email, displayName, company } = user;

          const users = db
            .collection(projectName)
            .doc("users")
            .collection("user")
            .doc(String(userId));
          const allusers = db
            .collection(projectName)
            .doc("users")
            .collection("user")
            .doc("all");

          if (passValidation()) {
            allusers.set(
              {
                [userId]: {
                  email,
                  displayName,
                  company,
                },
              },
              { merge: true }
            );

            users.set(
              {
                email,
                displayName,
                company,
              },
              { merge: true }
            );
            if (name === "email") {
              const sendEmailLinkInvitation = firebase
                .functions()
                .httpsCallable("sendEmailLink");

              sendEmailLinkInvitation({
                email: value,
                projectName,
                name: displayName,
              })
                .then(() => {
                  console.log("Magic link has been sent!");
                })
                .catch((error) => {
                  console.error(error);
                });
            }
          }
        } else {
          throw new Error("Not authorised to perform request");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const passValidation = () => {
    const errorsArray = Object.values(error).map((err) => {
      return err ? true : false;
    });

    const includesError = errorsArray.includes(true);

    return !includesError;
  };

  return (
    <>
      <div className="page">
        <div className="toolbar">
          <div className="toolbar-items">
            {admin && (
              <button
                className="secondary back"
                onClick={() => history.push(`/${projectName}/users`)}
                disabled={false}
              >
                <KeyboardArrowLeft />
                Dashboard
              </button>
            )}
          </div>

          <div className="toolbar-items" style={{ alignItems: "center" }}>
            {admin ? (
              <h1 className="users-title">{user.displayName}</h1>
            ) : (
              <>
                <h1 className="users-title">Your assessment centre</h1>
                <h2
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "normal",
                  }}
                >
                  Welcome back to the Culture Equality Index <br />
                  Take assessments and view your results and recommendations
                </h2>

                {!admin && pendingResults && (
                  <button
                    className="primary"
                    style={{ width: "20rem" }}
                    onClick={() => {
                      history.push(`/${projectName}/assessment-intro`);
                    }}
                    disabled={false}
                  >
                    Start assessment
                  </button>
                )}
              </>
            )}
          </div>

          <div className="toolbar-items">
            {admin && (
              <button
                className="tertiary action-button"
                onClick={() => {
                  const sendAnotherInvitiation = firebase
                    .functions()
                    .httpsCallable("sendAnotherInvitiation");
                  const { userId } = match.params;

                  sendAnotherInvitiation({ uid: userId })
                    .then(() => {
                      setAlerts(`${user.email} invited`);
                      const sendEmailLinkInvitation = firebase
                        .functions()
                        .httpsCallable("sendEmailLink");

                      sendEmailLinkInvitation({
                        email: user.email,
                        projectName,
                      })
                        .then(() => {
                          console.log("Magic link has been sent!");
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                      setTimeout(() => {
                        setAlerts("");
                      }, 5000);

                      const users = db
                        .collection(projectName)
                        .doc("users")
                        .collection("user")
                        .doc(String(user.uid));
                      const allusers = db
                        .collection(projectName)
                        .doc("users")
                        .collection("user")
                        .doc("all");

                      allusers.set(
                        {
                          [String(user.uid)]: {
                            pendingResults: true,
                          },
                        },
                        { merge: true }
                      );

                      users.set(
                        {
                          pendingResults: true,
                        },
                        { merge: true }
                      );
                    })
                    .catch((e) => console.error(e.message, "e.message"));
                }}
                disabled={false}
              >
                <Send />
              </button>
            )}
          </div>
        </div>

        {admin && (
          <div className="user-container">
            <div className="user-inputs">
              <div>
                <label className={`${error.displayName ? "error" : ""}`}>
                  Name
                </label>
                {editUser.displayName ? (
                  <p
                    style={{ display: "flex", alignItems: "center", margin: 0 }}
                  >
                    <input
                      value={user.displayName}
                      label="Name"
                      maxLength="30"
                      onChange={(e) => {
                        if (/^[A-Za-z-\s]*$/gi.test(e.target.value)) {
                          setUser({
                            ...user,
                            company: user.company ? user.company : "",
                            email: user.email ? user.email : "",
                            displayName: e.target.value,
                          });
                        }
                      }}
                      onBlur={(e) => {
                        if (/^[A-Za-z-\s]*$/gi.test(e.target.value)) {
                          validate(e.target.value, "displayName");
                        }
                      }}
                      style={{
                        border: error.displayName ? "red solid 1px" : "",
                      }}
                    />
                    <Done
                      onClick={() => {
                        validate(user.displayName, "displayName");
                      }}
                    />
                  </p>
                ) : (
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0.75rem 1rem",
                      margin: "0.5rem 0",
                      minHeight: `${!user.displayName ? "22px" : ""}`,
                    }}
                  >
                    {user.displayName}

                    <Edit
                      onClick={() => {
                        setEditUser({
                          company: editUser.company,
                          email: editUser.email,
                          displayName: !editUser.displayName,
                        });
                      }}
                    />
                  </p>
                )}
              </div>
              <hr className="divider" />
              <div>
                <label className={`${error.company ? "error" : ""}`}>
                  Company
                </label>
                {editUser.company ? (
                  <p
                    style={{ display: "flex", alignItems: "center", margin: 0 }}
                  >
                    <input
                      value={user.company}
                      label="Company"
                      maxLength="30"
                      onChange={(e) => {
                        if (/^[A-Za-z\s0-9]+$/gi.test(e.target.value)) {
                          setUser({
                            ...user,
                            company: e.target.value,
                            email: user.email ? user.email : "",
                            displayName: user.displayName
                              ? user.displayName
                              : "",
                          });
                        }
                      }}
                      onBlur={(e) => {
                        if (/^[A-Za-z\s0-9]+$/gi.test(e.target.value)) {
                          validate(e.target.value, "company");
                        }
                      }}
                      style={{ border: error.company ? "red solid 1px" : "" }}
                    />
                    <Done
                      onClick={() => {
                        validate(user.company, "company");
                      }}
                    />
                  </p>
                ) : (
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0.75rem 1rem",
                      margin: "0.5rem 0",
                      minHeight: `${!user.company ? "22px" : ""}`,
                    }}
                  >
                    {user.company}
                    <Edit
                      onClick={() => {
                        setEditUser({
                          company: !editUser.company,
                          email: editUser.email,
                          displayName: editUser.displayName,
                        });
                      }}
                    />
                  </p>
                )}
              </div>
              <hr className="divider" />
              <div>
                <label className={`${error.email ? "error" : ""}`}>Email</label>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0.75rem 1rem",
                    margin: "0.5rem 0",
                    minHeight: `${!user.email ? "22px" : ""}`,
                  }}
                >
                  {user.email}
                </p>
              </div>
            </div>

            {(error.displayName || error.company || error.email) && (
              <div className="error-container">
                <p className="error">
                  {error.displayName && error.displayName}
                </p>
                <p className="error">{error.company && error.company}</p>
                <p className="error">{error.email && error.email}</p>
              </div>
            )}
          </div>
        )}

        <div>
          <p className="selector">
            <strong>
              Assessments completed (
              {typeof assessments === "object" &&
              Object.keys(assessments).length
                ? Object.keys(assessments).length
                : 0}
              )
            </strong>
          </p>
          <div className="assessments-container">
            {typeof assessments === "object" &&
              Object.keys(assessments)
                .sort()
                .map((timeStamp, index) => {
                  const {
                    assessmentTimestampStart,
                    assessmentTimestampEnd,
                  } = assessments[timeStamp];

                  return (
                    <div className="assessment-container" key={`user-${index}`}>
                      {/* <div className="download-container">
                        <GetApp />
                      </div> */}
                      <div className="icon">
                        <Assessment />
                      </div>
                      <p className="selector">
                        {/* <strong>Culture Equality Index</strong> */}
                      </p>
                      <div className="assessment-stats">
                        <div className="assessment-data">
                          <label>Date</label>
                          <p className="small">
                            {moment(assessmentTimestampStart).format(
                              "YYYY/MM/DD"
                            )}
                          </p>
                        </div>
                        <hr />
                        <div className="assessment-data">
                          <label>Duration</label>
                          <p className="small">
                            {msToTime(
                              assessmentTimestampEnd - assessmentTimestampStart
                            )}
                          </p>
                        </div>
                        <hr />
                        <div className="assessment-data">
                          <label>Version</label>
                          <p className="small">{timeStamp}</p>
                        </div>
                      </div>
                      <button
                        className="tertiary "
                        onClick={() => {
                          const { userId, projectName } = match.params;
                          history.push(
                            `/user/${userId}/${projectName}/${timeStamp}`
                          );
                        }}
                        disabled={false}
                      >
                        View report
                      </button>
                    </div>
                  );
                })}
          </div>
        </div>

        {alerts && (
          <Snackbar
            open={true}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <SnackbarContent
              message={alerts}
              key="snack"
              style={{ marginBottom: "1rem" }}
            />
          </Snackbar>
        )}
      </div>
      <FooterV2 projectName={projectName} />
    </>
  );
};

export default memo(User);
