import React, { memo } from "react";
import PropTypes from "prop-types";
import goodJobIcon from "../../assets/icons/good-job.svg";
import wellDoneIcon from "../../assets/icons/well-done.svg";
import { currentSection, stringifyNumber } from "../../utils";
import { Feedback } from "../";

const AssessmentFeedback = (props) => {
  const {
    activeQuestion,
    assessmentQuestions,
    closeFeedbackMessage,
    completedAssessment,
  } = props;

  let progressText = "";

  switch (currentSection(activeQuestion, assessmentQuestions) - 1) {
    case 1:
      progressText = "a quarter of the";
      break;

    case 2:
      progressText = "half";
      break;

    case 3:
      progressText = "three quarters of the";
      break;

    default:
      break;
  }

  return (
    <>
      {currentSection(activeQuestion, assessmentQuestions) !== 5 ? (
        <Feedback
          closeFeedbackMessage={closeFeedbackMessage}
          icon={goodJobIcon}
          buttonText="Next"
        >
          <h2>
            Good job!
            <br />
            You’ve completed the{" "}
            {stringifyNumber(
              currentSection(activeQuestion, assessmentQuestions) - 1
            )}{" "}
            section
          </h2>
          <p>
            You are {progressText} way there! The questions you are answering
            help us identify your understanding and perceptions of equality in
            the workplace.
          </p>
        </Feedback>
      ) : (
        <Feedback
          closeFeedbackMessage={completedAssessment}
          icon={wellDoneIcon}
          buttonText="Next"
        >
          <h2>All done!</h2>
          <p className="selector feedback-subtitle">
            The following report provides insight based on your responses.
          </p>
          <div className="feedback-body">
            <p className="selector">
              <strong>What to expect from the report?</strong>
            </p>
            <p>
            Your organisation will be provided with a report detailing the collective strengths and development opportunities when it comes to creating cultures of equality. No individual responses will be provided, and individuals cannot be identified in the findings. 
              <br />
              <br />
              The responses will be aggregated and compared with a large group of people who have answered in the same questionnaire to measure employees’ experiences of inequality regarding policies, processes, practices, and personal beliefs. It will provide a baseline of your inclusive workplace culture, which helps establish a business case for why cultural change is needed. When considering this report’s description of your workplace culture, it is important to recognise that it is based on the answers given, representing how employees view behaviours and norms in relation to equality at work. 
              <br />
              <br />
            </p>
          </div>
        </Feedback>
      )}
    </>
  );
};

AssessmentFeedback.propTypes = {
  activeQuestion: PropTypes.number,
  assessmentQuestions: PropTypes.array,
  closeFeedbackMessage: PropTypes.func,
  completedAssessment: PropTypes.func,
};

AssessmentFeedback.defaultProps = {
  activeQuestion: 0,
  assessmentQuestions: [],
  closeFeedbackMessage: () => {},
  completedAssessment: () => {},
};

export default memo(AssessmentFeedback);
