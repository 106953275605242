import React, { useEffect, useState, useCallback } from "react";
import {
  Questions,
  ProgressBar,
  Footer,
  Feedback,
  OpenQuestion,
} from "../../components";
import firebase from "../../firebase";
import _ from "lodash";
import { currentSection } from "../../utils";
import { Add } from "@material-ui/icons";
// import welcomeIcon from "../../assets/icons/welcome.svg";

const Onboarding = (props) => {
  const { history, match, user } = props;
  const [onboardingQuestions, setOnboardingQuestions] = useState([]);
  const [onboardingAnswers, setOnboardingAnswers] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
  const [liveVersion, setLiveVersion] = useState(null);
  const [userName, setUserName] = useState(null);
  const [dbUserName, setDbUserName] = useState(null);
  const [nameError, setNameError] = useState("");

  const changeAnswers = (data, index) => {
    let items;
    items = [...onboardingAnswers];
    let item = { ...items[index] };
    item = data;
    items[index] = item;
    setOnboardingAnswers(items);
  };

  const onExit = useCallback((event) => {
    event.preventDefault();

    return (event.returnValue = "Are you sure you want to close?");
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", onExit);

    return () => {
      window.removeEventListener("beforeunload", () => onExit);
    };
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            /**
             * CHECK CURRENT USER IS ADMIN
             */
            const { claims } = idTokenResult;

            if (claims.isAdmin) {
              history.push(`/${projectName}/users`);
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });
  }, [history]);

  const { projectName } = match.params;

  useEffect(() => {
    const db = firebase.firestore();
    const liveAssessmentVersion = db
      .collection(projectName)
      .doc("cms")
      .collection("published")
      .doc("live");

    const localUser = db
      .collection(projectName)
      .doc("users")
      .collection("user")
      .doc(String(user.uid))
      .get();

    localUser.then((doc) => {
      setDbUserName(doc.data().displayName);
    });

    const unsubscribe = liveAssessmentVersion.onSnapshot((doc) => {
      if (!_.isEqual(liveVersion, doc.data().version)) {
        setLiveVersion(doc.data().version);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (liveVersion) {
      const db = firebase.firestore();
      const currentOnboarding = db
        .collection(projectName)
        .doc("cms")
        .collection("version")
        .doc(liveVersion)
        .collection("onboarding")
        .doc("questions")
        .get();

      currentOnboarding.then((doc) => {
        const onboardingQuestionsFirebase = doc.data().questions;
        const emptyAnswersArray = [];

        const shouldInitialiseAnswers =
          emptyAnswersArray.length === onboardingQuestionsFirebase.length &&
          onboardingAnswers.length !== onboardingQuestionsFirebase.length;

        if (!_.isEqual(onboardingQuestionsFirebase, onboardingQuestions)) {
          setOnboardingQuestions(onboardingQuestionsFirebase);
          onboardingQuestionsFirebase.map(() =>
            emptyAnswersArray.push({
              value: "",
              category: "",
            })
          );
        }

        if (shouldInitialiseAnswers) {
          setOnboardingAnswers(emptyAnswersArray);
        }
      });
    }
  }, [onboardingQuestions, onboardingAnswers, liveVersion]);

  const nextOnboardingQuestion = () => {
    if (activeQuestion + 1 === onboardingQuestions.length) {
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then(() => {
          const db = firebase.firestore();
          const onboardings = db
            .collection(projectName)
            .doc("onboardings")
            .collection(liveVersion)
            .doc(user.uid);

          const allOnboardings = db
            .collection(projectName)
            .doc("onboardings")
            .collection(liveVersion)
            .doc("all");

          onboardings.set(
            {
              onboarding: onboardingAnswers,
            },
            { merge: true }
          );

          allOnboardings.set(
            {
              [String(user.uid)]: {
                onboarding: onboardingAnswers,
              },
            },
            { merge: true }
          );
        })
        .catch((err) => {
          console.error(err);
        });

      const acceptedPrivacyPolicyCloudFunction = firebase
        .functions()
        .httpsCallable("acceptedPrivacyPolicy");

      acceptedPrivacyPolicyCloudFunction({
        uid: user.uid,
      });

      setActiveQuestion(0);
      history.push(`/${projectName}/assessment-intro`);
    } else {
      setActiveQuestion(activeQuestion + 1);
    }
  };

  const previousOnboardingQuestion = () => {
    setActiveQuestion(activeQuestion - 1);
  };

  const closeFeedbackMessage = () => {
    setShowWelcomeMessage(false);
  };

  const handleChangeUsername = (e) => {
    setNameError("");
    setUserName(e.target.value);
  };

  const validateName = () => {
    if (!userName) {
      setNameError(`Please input your name`);
      return false;
    } else {
      const validationRegex = /^[A-Za-z-\s]*$/;

      const passingValidation = validationRegex.test(
        String(userName).toLowerCase()
      );

      if (passingValidation) {
        return true;
      } else {
        setNameError("Please remove any non alphabetic characters");
        return false;
      }
    }
  };

  const addName = () => {
    if (validateName()) {
      const updateUserFunc = firebase.functions().httpsCallable("updateUser");
      const userId = user?.uid;

      updateUserFunc({
        userId,
        email: user.email,
        displayName: userName,
        company: "Play",
      }).then((user) => {
        const db = firebase.firestore();

        const users = db
          .collection(projectName)
          .doc("users")
          .collection("user")
          .doc(String(userId));

        const allusers = db
          .collection(projectName)
          .doc("users")
          .collection("user")
          .doc("all");

        allusers.set(
          {
            [userId]: {
              displayName: userName,
            },
          },
          { merge: true }
        );

        users.set(
          {
            displayName: userName,
          },
          { merge: true }
        );
        setDbUserName(userName);
      });
    }
  };

  const renderFooter = () => {
    if (!showWelcomeMessage) {
      if (!!dbUserName) {
        return (
          <Footer
            answers={onboardingAnswers}
            activeQuestion={activeQuestion}
            nextQuestion={nextOnboardingQuestion}
            previousQuestion={previousOnboardingQuestion}
            isAssessment={false}
            numberOfQuestions={onboardingQuestions.length}
          />
        );
      }
    }
  };

  return (
    <>
      <div className="onboarding">
        {activeQuestion === 0 && showWelcomeMessage ? (
          <Feedback
            closeFeedbackMessage={closeFeedbackMessage}
            buttonText="Continue"
          >
            <h2>Great! It is good to have you with us.</h2>
            <p>
              First, we need you to complete a few questions about yourself and
              your job. You only need to do them once. We will store them for
              the next time you visit. Once completed you will be ready to take
              the assessment.
            </p>
          </Feedback>
        ) : (
          <>
            {!dbUserName ? (
              <>
                <OpenQuestion
                  question="Please enter your full name"
                  maxLength="35"
                  onChangeAction={handleChangeUsername}
                  errorMessage={nameError}
                />
                <button
                  className="primary username-button"
                  onClick={() => addName()}
                >
                  Save and Proceed
                </button>
              </>
            ) : (
              <>
                {onboardingQuestions.length > 0 && (
                  <div>
                    <ProgressBar
                      activeQuestion={activeQuestion + 1}
                      questions={onboardingQuestions}
                      sectionCompleted={false}
                      sectionCompletedVal={false}
                      currentSection={currentSection(
                        activeQuestion,
                        onboardingQuestions
                      )}
                      sectionsRequired={2}
                    />

                    <>
                      {onboardingQuestions.map(
                        (question, index) =>
                          index === activeQuestion && (
                            <Questions
                              options={question.options}
                              helperText={
                                "None of the following questions will be used to identify you. All of your answers will be kept strictly confidential and will only be used in aggregate."
                              }
                              body={question.body}
                              key={`onboarding-${index}`}
                              setAnswer={(onboardingObject) => {
                                changeAnswers(
                                  onboardingObject,
                                  index,
                                  "onboarding"
                                );
                              }}
                              answer={
                                onboardingAnswers[index] &&
                                onboardingAnswers[index].value
                                  ? onboardingAnswers[index].value
                                  : ""
                              }
                            />
                          )
                      )}
                    </>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>

      {renderFooter()}
    </>
  );
};

export default Onboarding;
